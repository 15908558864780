<template>
  <div>
    <a-select style="width: 240px;margin-right:8px" v-model="ticketData.instanceId" @change="handleChangeInstance"
              placeholder="请选择数据源实例" :filter-option="filterOption" show-search>
      <a-select-option v-for="ds in allDsList" :value="ds.id" :key="ds.id">
        <cc-data-source-icon color="#4DBAEE" :size="18" :type="ds.dataSourceType"
                             :instanceType="ds.deployEnvType"></cc-data-source-icon>
        {{ ds.instanceDesc ? ds.instanceDesc : ds.instanceId }}
      </a-select-option>
    </a-select>
    <!--    <cc-filter-select selectStyle="width: 180px;margin-right:8px" vModal="ticketData.database"-->
    <!--                      :defaultValue="ticketData.database"-->
    <!--                      :handleChange="handleChangeDb" placeholder="请选择数据库"-->
    <!--                      :dataList="ticketData.dbList"></cc-filter-select>-->
    <!--    <cc-filter-select v-if="hasSchema(selectedDs.dataSourceType)" selectStyle="width: 180px"-->
    <!--                      vModal="ticketData.schema" :defaultValue="ticketData.schema" :handleChange="handleChangeSchema"-->
    <!--                      placeholder="请选择schema" :dataList="ticketData.schemaList"></cc-filter-select>-->
    <a-select style="width: 200px;margin-right:8px" v-model="ticketData.database" @change="handleChangeDb"
              placeholder="请选择数据库" :filter-option="filterOption" show-search v-if="!noStruct(selectedDs.dataSourceType)">
      <a-select-option v-for="db in ticketData.dbList" :value="db" :key="db">
        <cc-iconfont name="shujuku" color="#999999" :size="12"></cc-iconfont>
        {{
          db
        }}
      </a-select-option>
    </a-select>
    <a-select v-if="hasSchema(selectedDs.dataSourceType)" style="width: 180px" v-model="ticketData.schema"
              @change="handleChangeSchema"
              placeholder="请选择Schema" :filter-option="filterOption" show-search>
      <a-select-option v-for="db in ticketData.schemaList" :value="db.name" :key="db.name">
        <cc-iconfont name="schema" color="#999999" :size="12"></cc-iconfont>
        {{
          db.name
        }}
      </a-select-option>
    </a-select>
  </div>
</template>
<script>

import { hasSchema, noStruct } from '../../../utils/index';

export default {
  props: {
    ds: Object,
    ticketData: Object,
    allDsList: Array,
    handleChangeInstance: Function,
    handleChangeDb: Function,
    selectedDs: Object,
    handleChangeSchema: Function
  },
  data() {
    return {
      hasSchema,
      noStruct
    };
  },
  methods: {
    filterOption(input, option) {
      return (
        option.componentOptions.children[1].text.toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    }
  }
};
</script>
