<template>
  <div class="ticket-detail-container">
    <a-breadcrumb>
      <a-breadcrumb-item><a href="/#/ticket">工单</a></a-breadcrumb-item>
      <a-breadcrumb-item>工单详情</a-breadcrumb-item>
    </a-breadcrumb>
    <a-button @click="getTicketDetail('refresh')" class="refresh-btn" :loading="loading">刷新</a-button>
    <a-card dis-hover class="ticket-detail-status">
      <div slot="title">
        <p class="ticket-title-p">
          <span class="ticket-title">{{ ticketDetail.ticketTitle }}</span>
          <span class="ticket-status-total">{{ TICKET_STATUS[ticketDetail.ticketStatus] }}</span>
        </p>
        <div class="ticket-detail-summary">
          <div class="ticket-detail-item">
            <span class="ticket-detail-item-title">申请人：</span>
            <span>{{ ticketDetail.userName }}</span>
          </div>
          <div class="ticket-detail-item">
            <span class="ticket-detail-item-title">环境：</span>
            <span>{{ ticketDetail.dsEnvName }}</span>
          </div>
          <div class="ticket-detail-item">
            <span class="ticket-detail-item-title">DB实例/库名：</span>
            <cc-data-source-icon color="#4DBAEE" :size="18" :type="ticketDetail.dataSourceType"
                                 :instanceType="ticketDetail.deployEnvType"></cc-data-source-icon>
            <span>{{
                ticketDetail.dataSourceDesc ? ticketDetail.dataSourceDesc : ticketDetail.dataSourceInstName
              }}{{ ticketDetail.targetInfo }}</span>
          </div>
          <div class="ticket-detail-item">
            <span class="ticket-detail-item-title">审批流模板：</span>
            <span>{{ ticketDetail.approTemplateName }}</span>
          </div>
          <!--          <div class="ticket-detail-item">-->
          <!--            <span class="ticket-detail-item-title">期望执行时间：</span>-->
          <!--            <span>{{ ticketDetail.expectedExecTime }}</span>-->
          <!--          </div>-->
          <div class="ticket-detail-item">
            <span class="ticket-detail-item-title">预估影响行数：</span>
            <span>{{ ticketDetail.expectedAffectedRows }}</span>
          </div>
        </div>
        <div class="ticket-detail-operators">
          <a-button class="warning-btn"
                    v-if="currentStep===1 && userInfo.authArr.includes('TICKET_CONFIRM') && hasPermission" ghost
                    @click="handleConfirm('REFUSE')">拒绝执行
          </a-button>
          <a-button type="primary"
                    v-if="currentStep===1 && userInfo.authArr.includes('TICKET_CONFIRM')  && hasPermission"
                    @click="handleConfirm('CONFIRM_AND_AUTO_EXEC')">自动执行
          </a-button>
          <a-button type="primary"
                    v-if="currentStep===1 && userInfo.authArr.includes('TICKET_CONFIRM')  && hasPermission"
                    @click="handleConfirm('CONFIRM_AND_MANUAL_EXEC')">手动执行
          </a-button>
          <a-button type="primary"
                    v-if="(currentStep===0||currentStep===1)&&role==='owner'&&ticketDetail.ticketStatus!=='CANCELED'"
                    @click="handleCancel"
                    ghost>撤销
          </a-button>
          <a-button type="primary"
                    v-if="currentStep===2&&ticketDetail.ticketStatus==='EXEC_FAIL'  && userInfo.authArr.includes('TICKET_RETRY')  && hasPermission"
                    @click="retryTicket"
                    ghost>重试
          </a-button>
          <a-button type="warning"
                    v-if="currentStep===2&&ticketDetail.ticketStatus==='EXEC_FAIL' && userInfo.authArr.includes('TICKET_CLOSE')  && hasPermission"
                    @click="closeTicket"
                    ghost>关闭工单
          </a-button>
        </div>
      </div>
      <div class="ticket-detail-wrapper" v-if="ticketDetail.ticketProcessVOList">
        <div class="step-line">

        </div>
        <div class="step-item">
          <span
            :class="`step-status-point step-status-success`"></span>
          <div class="step-item-item">
            <span>创建任务</span>
          </div>
          <div class="step-item-item">
            <span class="step-detail-label">申请人：</span>
            <span class="step-detail-value">{{ ticketDetail.userName }}</span>
          </div>
          <div class="step-item-item">
            <span class="step-detail-label">状态：</span>
            <span class="step-detail-value">
              <div class="ticket-status"
                   style="color:#52C41A">
                  <div class="type" style="background:#52C41A"></div>
                  <div class="content">已提交</div>
              </div>
            </span>
          </div>
          <div class="step-item-item">
            <span class="step-detail-label">执行时间：</span>
            <span class="step-detail-value">{{ ticketDetail.gmtCreate }}</span>
          </div>
          <div class="step-item-item">
            <span class="step-detail-label">需求描述：</span>
            <span class="step-detail-value">{{ ticketDetail.description }}</span>
          </div>
        </div>
        <div class="step-item">
          <span
            :class="`step-status-point ${ticketDetail.ticketProcessVOList[0].finished?'step-status-success':'step-status-ing'}`"></span>
          <div class="step-item-item">
            <span>审批</span>
          </div>
          <div class="apply-container">
            <div class="apply-dm-level">
              <div v-if="ifExpand" class="apply-contain-wrapper"></div>
              <div class="step-item-item">
                <cc-iconfont v-if="!ifExpand" :size=12 name="expand" @click.native="handleExpand(true)"/>
                <cc-iconfont v-if="ifExpand" :size=12 name="close" @click.native="handleExpand(false)"/>
                <span class="step-detail-label">状态：</span>
                <span class="step-detail-value">
                  <TicketStatus :ticketDetail="ticketDetail" :currentStep="0"></TicketStatus>
                </span>
              </div>
              <div class="step-item-item">
                <span class="step-detail-label">审批完成时间：</span>
                <span class="step-detail-value">
              {{
                    getCurrentApproveInfo.finishTime
                  }}
            </span>
              </div>
            </div>
            <div v-if="ifExpand">
              <div
                v-for="stage in JSON.parse(this.ticketDetail.ticketProcessVOList[0].stageContext).approNodeDetailList"
                :key="stage.id">
                <div class="step-item-item">
                  <span class="step-detail-label">节点类型：</span>
                  <span class="step-detail-value">
                  {{ DINGDING_STAGE_TYPE[stage.dingOpRecordType] }}
                </span>
                </div>
                <div class="step-item-item">
                  <span class="step-detail-label">处理人：</span>
                  <span class="step-detail-value">
              {{
                      stage.reviewerName
                    }}
            </span>
                </div>
                <div class="step-item-item">
                  <span class="step-detail-label">处理结果：</span>
                  <span class="step-detail-value"
                        :style="`color:${stage.dingApproNodeOpResult === 'AGREE'?'#52C41A':stage.dingApproNodeOpResult === 'REFUSE'?'#FF1815':''}`">
                    <TicketStatus :ticketDetail="ticketDetail" :currentStep="0" v-if="stage.dingOpRecordType==='EXECUTE_TASK_NORMAL'&&stage.dingApproNodeOpResult==='NONE'"></TicketStatus>
                    <span v-if="!(stage.dingOpRecordType==='EXECUTE_TASK_NORMAL'&&stage.dingApproNodeOpResult==='NONE')">{{DINGDING_STAGE_RESULT[stage.dingApproNodeOpResult]}}</span>
                </span>
                </div>
                <div class="step-item-item">
                  <span class="step-detail-label">处理时间：</span>
                  <span class="step-detail-value">
              {{
                      stage.finishTime
                    }}
            </span>
                </div>
                <div class="step-item-item">
                  <span class="step-detail-label">备注：</span>
                  <span class="step-detail-value">{{
                      stage.remark
                    }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="step-item">
          <span
            :class="`step-status-point
            ${ticketDetail.ticketProcessVOList[0].finished?ticketDetail.ticketProcessVOList[1].finished?
            'step-status-success':'step-status-ing':''}`"></span>
          <div class="step-item-item">
            <span>确认</span>
          </div>
          <div class="step-item-item">
            <span></span>
            <span class="step-detail-label">确认人：</span>
            <span class="step-detail-value">
              {{
                ticketDetail.ticketProcessVOList[1].stageContext ?
                  JSON.parse(ticketDetail.ticketProcessVOList[1].stageContext).confirmUserName : ''
              }}
            </span>
          </div>
          <div class="step-item-item">
            <span class="step-detail-label">状态：</span>
            <span class="step-detail-value">
            <TicketStatus :ticketDetail="ticketDetail" :currentStep="1"></TicketStatus>
            </span>
          </div>
          <div class="step-item-item">
            <span class="step-detail-label">确认时间：</span>
            <span class="step-detail-value">{{ ticketDetail.ticketProcessVOList[1].finishTime }}</span>
          </div>
          <div class="step-item-item">
            <span class="step-detail-label">备注：</span>
            <span class="step-detail-value">
              {{
                ticketDetail.ticketProcessVOList[1].stageContext ? JSON.parse(ticketDetail.ticketProcessVOList[1].stageContext).confirmMsg : ''
              }}
            </span>
          </div>
        </div>
        <div class="step-item">
          <span
            :class="`step-status-point
            ${ticketDetail.ticketProcessVOList[1].finished?ticketDetail.ticketProcessVOList[2].finished?
            'step-status-success':'step-status-ing':''}`"></span>
          <div class="step-item-item">
            <span>执行</span>
          </div>
          <div class="step-item-item">
            <span></span>
            <span class="step-detail-label">执行人：</span>
            <span class="step-detail-value">{{
                ticketDetail.ticketProcessVOList[2].stageContext
                  ? JSON.parse(ticketDetail.ticketProcessVOList[2].stageContext).execUserName === 'system'
                  ? '系统自动执行' : JSON.parse(ticketDetail.ticketProcessVOList[2].stageContext).execUserName : ''
              }}</span>
          </div>
          <div class="step-item-item">
            <span class="step-detail-label">状态：</span>
            <span class="step-detail-value">
              <TicketStatus :ticketDetail="ticketDetail" :currentStep="2"></TicketStatus>
            </span>
          </div>
          <div class="step-item-item">
            <span class="step-detail-label">执行时间：</span>
            <span class="step-detail-value">{{ ticketDetail.ticketProcessVOList[2].finishTime }}</span>
          </div>
          <div class="step-item-item">
            <span class="step-detail-label">备注：</span>
            <span class="step-detail-value">
              {{ ticketDetail.ticketProcessVOList[2]&&JSON.parse(ticketDetail.ticketProcessVOList[2].stageContext)?JSON.parse(ticketDetail.ticketProcessVOList[2].stageContext).execMsg:'' }}
            </span>
          </div>
        </div>
      </div>
    </a-card>
    <a-collapse class="ticket-content" :forceRender="true" v-model="activeKey" @change="handleOpenContent">
      <a-collapse-panel key="1" header="工单内容">
        <div ref="codemirror" class="codemirrorHook"></div>
      </a-collapse-panel>
    </a-collapse>
<!--    <a-card class="ticket-content" dis-hover>-->
<!--      <p slot="title">工单内容</p>-->
<!--      <div>-->
<!--        &lt;!&ndash;        <pre>{{ ticketDetail.rawSql }}</pre>&ndash;&gt;-->
<!--        <div ref="codemirror" class="codemirrorHook"></div>-->
<!--      </div>-->
<!--    </a-card>-->
<!--    <a-card class="ticket-content" dis-hover v-if="currentStep===2">-->
<!--      <p slot="title">执行详情</p>-->
<!--      <div>-->
<!--        <a-table border size="small" :columns="execColumns" :data-source="execData">-->
<!--        <span slot="result" slot-scope="row">-->
<!--          <span :style="`color:${row.execResult && JSON.parse(row.execResult).success ? '#52C41A' : '#FF1815'}`">-->
<!--            {{ row.execResult ? JSON.parse(row.execResult).success ? '成功' : '失败' : '' }}-->
<!--          </span>-->
<!--        </span>-->
<!--          <span slot="message" slot-scope="row">-->
<!--          {{ row.execResult ? JSON.parse(row.execResult).message : '' }}-->
<!--        </span>-->
<!--          <span slot="executeTime" slot-scope="row">-->
<!--          {{ row.execResult ? JSON.parse(row.execResult).executeTime : '' }}-->
<!--        </span>-->
<!--        </a-table>-->
<!--      </div>-->
<!--    </a-card>-->
    <a-card class="ticket-content" dis-hover v-if="currentStep===2 && taskList.length">
      <p slot="title">执行任务列表</p>
      <async-job-detail type="ticket" :export-job-id="taskList[0].id" ref="asyncJobDetailRef" />
    </a-card>
    <a-modal
      :visible="showCancelTicket"
      title="撤销工单确认"
    >
      <p>工单撤销后不可恢复，确认要撤销该工单吗？</p>
      <div class="footer">
        <a-button type="primary" @click="cancelTicket">确定</a-button>
        <a-button @click="handleConfirmCancel">取消</a-button>
      </div>
    </a-modal>
    <a-modal
      :visible="showConfirmModal"
      title="理由"
      footer-hide
    >
      <a-input v-model="confirmInfo" type="textarea" placeholder="请输入操作理由"/>
      <div class="footer">
        <a-button type="primary" @click="confirmTicket">确定</a-button>
        <a-button @click="handleConfirmCancel">取消</a-button>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import TicketStatus from '@views/ticket/components/TicketStatus.vue';
import * as CodeMirror from '../sql/components/codemirror/lib/codemirror';
// eslint-disable-next-line import/no-unresolved
import '../sql/components/codemirror/addon/hint/show-hint.css';
// eslint-disable-next-line import/no-unresolved
import '../sql/style/cc-editor.css';
// eslint-disable-next-line import/no-unresolved
import '../sql/components/codemirror/lib/codemirror.css';
// eslint-disable-next-line import/no-unresolved,import/extensions
import '../sql/components/codemirror/lib/codemirror';
// eslint-disable-next-line import/no-unresolved,import/extensions
import '../sql/components/codemirror/mode/sql/sql';
// eslint-disable-next-line import/no-unresolved,import/extensions
import '../sql/components/codemirror/custom/custome-sql-hint';
// import '../sql/components/addon/hint/show-hint';
// eslint-disable-next-line import/no-unresolved,import/extensions
import '../sql/components/codemirror/addon/hint/sql-hint';
// eslint-disable-next-line import/no-unresolved,import/extensions
import '../sql/components/codemirror/keymap/sublime';
import {
  TICKET_STATUS, DINGDING_STAGE_TYPE, DINGDING_STAGE_RESULT, TICKET_STATUS_COLOR, BIZ_TYPE
} from '../../consts/index';
import AsyncJobDetail from '../system/AsyncJobList/AsyncJobDetail.vue';

export default {
  name: 'Login',
  components: {
    AsyncJobDetail,
    TicketStatus
  },
  data() {
    return {
      taskList: [],
      startId: 0,
      pageSize: 10,
      exportJobList: [],
      preStartIds: [],
      activeKey: ['1'],
      role: 'applyer',
      ticketId: 0,
      ticketDetail: {},
      TICKET_STATUS,
      DINGDING_STAGE_TYPE,
      DINGDING_STAGE_RESULT,
      TICKET_STATUS_COLOR,
      loading: false,
      showConfirmModal: false,
      confirmType: '',
      confirmInfo: '',
      ifExpand: true,
      showCancelTicket: false,
      currentStep: 0,
      myCodeMirror: null,
      hasPermission: false,
      stepList: [
        {
          id: 1,
          applyer: 'pudding',
          status: 'SUCCESS',
          applyTime: '2021-01-07 11:00:00',
          desc: '测试测试'
        },
        {
          id: 2,
          applyer: 'pudding',
          status: 'ERROR',
          applyTime: '2021-01-07 11:00:00',
          desc: '测试测试'
        }
      ],
      execColumns: [
        {
          title: '序号',
          key: 'seqIndex',
          dataIndex: 'seqIndex',
          width: 60
        },
        {
          title: '执行时间',
          dataIndex: 'gmtCreate',
          key: 'gmtCreate',
          width: 180
        },
        {
          title: '结果',
          width: 80,
          slots: { title: 'result' },
          scopedSlots: { customRender: 'result' }
        },
        {
          title: '执行sql',
          dataIndex: 'parsedSql',
          key: 'parsedSql'
        },
        {
          title: '执行信息',
          slots: { title: 'message' },
          scopedSlots: { customRender: 'message' }
        },
        {
          title: '执行耗时(ms)',
          width: 120,
          slots: { title: 'executeTime' },
          scopedSlots: { customRender: 'executeTime' }
        }
      ],
      execData: []
    };
  },
  mounted() {
    this.ticketId = this.$route.params.id;
    this.getTicketDetail('init');
    this.checkTicketPermission();
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.userInfo
    }),
    getCurrentApproveInfo() {
      const currentInfo = JSON.parse(this.ticketDetail.ticketProcessVOList[0].stageContext);
      if (currentInfo.approNodeDetailList) {
        return currentInfo.approNodeDetailList[currentInfo.approNodeDetailList.length - 1];
      }
      return '';
    },
    styleVar() {
      return (n) => ({
        '--status-color': TICKET_STATUS_COLOR[this.ticketDetail.curTicketProcessId > this.ticketDetail.ticketProcessVOList[n].ticketProcessId
          ? 'FINISHED' : this.ticketDetail.curTicketProcessId === this.ticketDetail.ticketProcessVOList[n].ticketProcessId
            ? this.ticketDetail.ticketStatus : '']
      });
    }
  },
  methods: {
    async getTicketDetail(type) {
      this.loading = true;
      const data = {
        ticketId: this.ticketId
      };
      const res = await this.$services.queryTicketsDetail({ data });
      if (res.success) {
        this.ticketDetail = res.data;
        this.execData = this.ticketDetail.sqlProcessVOList;
        this.ticketDetail.ticketProcessVOList.map((item, index) => {
          if (item.ticketProcessId === this.ticketDetail.curTicketProcessId) {
            this.currentStep = index;
          }
          return null;
        });
        if (this.ticketDetail.userName === this.$store.state.userInfo.username) {
          this.role = 'owner';
        }
        if (this.$refs.codemirror.childNodes.length === 0) {
          const myCodemirror = CodeMirror(this.$refs.codemirror, {
            tabSize: 4,
            lineNumbers: true,
            line: true,
            indentWithTabs: true,
            smartIndent: true,
            autofocus: false,
            mode: 'text/x-mysql',
            theme: 'cc-editor',
            keyMap: 'sublime',
            escape: true,
            readOnly: true,
            extraKeys: {
              'Ctrl-Enter': this.onRun
            }
          });
          this.myCodeMirror = myCodemirror;
        }
        this.myCodeMirror.setValue(this.ticketDetail.rawSql);
        if (type === 'init') {
          this.activeKey = [];
        }

        const taskRes = await this.$services.listExportJoBbyBiz({
          data: {
            startId: this.startId,
            pageSize: this.pageSize,
            bizType: BIZ_TYPE.TICKETS_WORKFLOW,
            exportBizId: res.data.id
          }
        });
        if (taskRes.data.length) {
          this.taskList = taskRes.data;
          if (type === 'refresh') {
            await this.$refs.asyncJobDetailRef.getJobDetail();
          }
        }
      }
      this.loading = false;
    },
    async checkTicketPermission() {
      const res = await this.$services.checkPermissions({ data: { ticketId: this.ticketId } });
      if (res.success) {
        this.hasPermission = res.data;
      }
    },
    async cancelTicket() {
      const data = {
        ticketId: this.ticketId,
        approvalType: this.ticketDetail.approType,
        approIdentity: this.ticketDetail.approIdentity
      };
      const res = await this.$services.cancelTicket({ data });
      if (res.success) {
        this.$Message.success('撤销成功');
        this.showCancelTicket = false;
        this.getTicketDetail();
      }
    },
    handleCancel() {
      this.showCancelTicket = true;
    },
    async confirmTicket() {
      const data = {
        ticketId: this.ticketId,
        confirmActionType: this.confirmType,
        remark: this.confirmInfo
      };
      const res = await this.$services.confirmTicket({ data });
      if (res.success) {
        this.$Message.success('操作成功');
        this.getTicketDetail();
      }
      this.showConfirmModal = false;
    },
    handleConfirm(type) {
      this.showConfirmModal = true;
      this.confirmType = type;
    },
    handleExpand(ifExpand) {
      this.ifExpand = ifExpand;
    },
    async retryTicket() {
      const data = {
        ticketId: this.ticketId
      };
      const res = await this.$services.retryTicket({ data });
      if (res.success) {
        this.$Message.success('重试成功');
        this.getTicketDetail();
      }
    },
    async closeTicket() {
      const data = {
        ticketId: this.ticketId
      };
      const res = await this.$services.closeTicket({ data });
      if (res.success) {
        this.$Message.success('关闭成功');
        this.getTicketDetail();
      }
    },
    handleConfirmCancel() {
      this.showConfirmModal = false;
      this.showCancelTicket = false;
    },
    handleOpenContent() {
      this.myCodeMirror.setValue(this.ticketDetail.rawSql);
    }
  }
};
</script>

<style lang="less">
.ticket-detail-container {
  padding: 20px;
  position: relative;

  .refresh-btn {
    position: absolute;
    right: 20px;
    top: 12px;
  }

  .ticket-detail-status {
    margin-top: 16px;
  }

  .ticket-title-p {
    line-height: 20px;
    margin-bottom: 12px;
  }

  .ivu-card-head p, .ivu-card-head-inner {
    overflow: visible;
  }

  .ticket-title {
    font-size: 14px;
    font-family: PingFangSC-Semibold;
    font-weight: 500;
  }

  .ticket-status-total {
    display: inline-block;
    border: 1px solid #F8D090;
    background: #FFF8EC;
    border-radius: 10px;
    color: #FFA30E;
    font-size: 12px;
    padding: 2px 8px;
    margin-left: 8px;
  }

  .ticket-detail-summary {
    font-size: 12px;
    font-family: PingFangSC-Regular;
    font-weight: 400;
    padding-right: 200px;

    .ticket-detail-item {
      margin-right: 80px;
      color: @font-color;
      display: inline-block;

      .ticket-detail-item-title {
        color: @icon-color;
      }
    }
  }

  .ticket-detail-operators {
    position: absolute;
    right: 14px;
    top: 10px;

    button {
      margin-left: 10px;
    }
  }

  .ticket-content {
    margin-top: 20px;
  }

  .ticket-detail-wrapper {
    position: relative;
    color: @font-color;

    .step-line {
      width: 2px;
      height: calc(100% - 18px);
      background-color: #E8E8E8;
      position: absolute;
      left: 6px;
      top: 12px;
      z-index: 0;
    }

    .step-item {
      margin-bottom: 20px;
      //display: flex;
      padding-right: 200px;
      //justify-content: space-between;

      .apply-container {
        display: inline-block;
        width: 72%;
        position: relative;
        vertical-align: top;
        line-height: 30px;

        .step-item-item {
          width: 20%;
          vertical-align: top;
        }

        .apply-contain-wrapper {
          width: 8px;
          height: calc(100% - 36px);
          position: absolute;
          left: -12px;
          top: 20px;
          border-left: 1px solid #C7C7C7;
          border-bottom: 1px solid #C7C7C7;
        }

        .apply-dm-level {
          .step-item-item {
            width: 25%;
            vertical-align: top;
          }
        }

        .icon-close {
          color: #C7C7C7;
          position: absolute;
          left: -20px;
          cursor: pointer;
        }
      }

      &:last-child {
        margin-bottom: 0;
      }

      .step-item-item {
        //flex-grow: 1;
        //width: 300px;
        display: inline-block;
        width: 18%;
        position: relative;

        .icon-expand {
          color: #C7C7C7;
          position: absolute;
          left: -20px;
          cursor: pointer;
        }
      }

    }

    .step-status-point {
      display: inline-block;
      width: 15px;
      height: 15px;
      border-radius: 50%;
      border: 1px solid #CCCCCC;
      background-color: #CCCCCC;
      margin-right: 8px;
      z-index: 1;
      position: relative;
    }

    .step-status-success {
      background-color: #ffffff;
      border-color: #0BB9F8;
    }

    .step-status-ing {
      background-color: #0BB9F8;
      border-color: #0BB9F8;
    }

    .step-status-error {
      background-color: #FF1815;
    }

    .step-detail-label {
      color: @icon-color;
    }

    .step-detail-value {
      display: inline-block;
    }
  }

  .ticket-status {
    display: flex;
    align-items: center;

    .type {
      .circle(8px, #52C41A);
      background: var(--status-color);
      background: var(--status-color);
      margin-right: 6px;
    }
  }

  .cm-s-cc-editor .CodeMirror-gutters {
    background: none !important;
  }
}

.modal-footer {
  margin-top: 20px;
  text-align: center;
}
</style>
