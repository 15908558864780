<template>
  <div>
    <div class="ticket-status"
         :style="`color:${TICKET_STATUS_COLOR[ticketDetail.curTicketProcessId > ticketDetail.ticketProcessVOList[currentStep].ticketProcessId ?
                    'FINISHED' : ticketDetail.curTicketProcessId == ticketDetail.ticketProcessVOList[currentStep].ticketProcessId ?
                    ticketDetail.ticketStatus : '']}`">
      <div class="type" :style="styleVar(currentStep)"/>
      <div class="content">{{
          ticketDetail.curTicketProcessId > ticketDetail.ticketProcessVOList[currentStep].ticketProcessId ?
            '已完成' : ticketDetail.curTicketProcessId == ticketDetail.ticketProcessVOList[currentStep].ticketProcessId ?
            TICKET_STATUS[ticketDetail.ticketStatus] : ''
        }}
      </div>
    </div>
  </div>
</template>
<script>
import {
  TICKET_STATUS, TICKET_STATUS_COLOR
} from '../../../consts/index';

export default {
  props: {
    currentStep: Number,
    ticketDetail: Object
  },
  data() {
    return {
      TICKET_STATUS,
      TICKET_STATUS_COLOR
    };
  },
  computed: {
    styleVar() {
      return (n) => ({
        '--status-color': TICKET_STATUS_COLOR[this.ticketDetail.curTicketProcessId > this.ticketDetail.ticketProcessVOList[n].ticketProcessId
          ? 'FINISHED' : this.ticketDetail.curTicketProcessId === this.ticketDetail.ticketProcessVOList[n].ticketProcessId
            ? this.ticketDetail.ticketStatus : '']
      });
    }
  }
};
</script>
<style lang="less" scoped>
.ticket-status {
  display: flex;
  align-items: center;

  .type {
    .circle(8px, #52C41A);
    background: var(--status-color);
    margin-right: 6px;
  }
}
</style>
