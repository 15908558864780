<template>
  <div class="ticket-container">
    <a-tabs type="card" :animated="false" v-model="ticketListType" @change="handleTypeChange">
      <a-tab-pane key="WAIT_SELF_PROCESS" tab="待我处理">
        <TicketList type="todo" :ticketData="ticketData" :handleRefresh="handleRefresh"></TicketList>
      </a-tab-pane>
      <a-tab-pane key="SELF_CREATE" tab="我发起的">
        <TicketList type="myTicket" :ticketData="ticketData" :handleRefresh="handleRefresh"></TicketList>
      </a-tab-pane>
      <a-tab-pane key="ALL" tab="全部">
        <TicketList type="toTicket" :ticketData="ticketData" :handleRefresh="handleRefresh"></TicketList>
      </a-tab-pane>
    </a-tabs>
  </div>
</template>

<script>
import TicketList from '@views/ticket/components/TicketList.vue';
import moment from 'moment';

export default {
  name: 'Home',
  components: { TicketList },
  data() {
    return {
      ticketData: [],
      ticketListType: 'WAIT_SELF_PROCESS',
      searchKey: {
        daterange: [moment(new Date(new Date().getTime() - 90 * 24 * 3600 * 1000), 'YYYY-MM-DD HH:mm'),
          moment(new Date(), 'YYYY-MM-DD HH:mm')],
        ticketStatus: '',
        type: '',
        ticketTitleName: ''
      }
    };
  },
  mounted() {
    this.listTickets(this.searchKey);
  },
  methods: {
    async listTickets(searchKey) {
      let data = {};
      if (searchKey) {
        data = {
          startTimeMs: new Date(searchKey.daterange[0]).getTime(),
          endTimeMs: new Date(searchKey.daterange[1]).getTime(),
          ticketTitleName: searchKey.ticketTitleName,
          ticketStatus: searchKey.ticketStatus,
          ticketListType: this.ticketListType
        };
      } else {
        data = {
          ticketListType: this.ticketListType
        };
      }
      const res = await this.$services.listTickets({ data });
      if (res.success) {
        this.ticketData = res.data;
      }
    },
    handleRefresh(searchKey) {
      this.listTickets(searchKey);
    },
    handleTypeChange() {
      this.handleRefresh(this.searchKey);
    }
  }
};
</script>

<style lang="less">
.ticket-container {
  padding: 20px;

  .filter-ticket-container {
    display: flex;
  }

  .ant-tabs {
    .ant-tabs-tab {
      height: 32px !important;
      line-height: 30px !important;
      border: 1px solid #C7C7C7 !important;
      margin-right: 0 !important;
      color: #333333 !important;
      background: none !important;

      &:hover {
        color: #0BB9F8 !important;
      }

      &:first-child {
        border-right: none !important;
      }

      &:last-child {
        border-left: none !important;
      }
    }

    .ant-tabs-tab-active {
      height: 32px !important;
      color: #333333 !important;
      font-weight: 400 !important;
      border-color: #C7C7C7 !important;
      border-bottom: none !important;
      position: relative;

      &:before {
        content: ' ';
        display: inline-block;
        width: 100%;
        height: 2px;
        position: absolute;
        left: 0;
        top: -1px;
        background: #0BB9F8;
      }
    }

    .ant-tabs-nav-container {
      height: 32px !important;
    }
  }
}
</style>
