<template>
  <div class="console-job-wrapper">
    <a-breadcrumb style="margin-bottom: 14px">
      <a-breadcrumb-item>消息中心</a-breadcrumb-item>
    </a-breadcrumb>
    <div class="page-header-container">
      <a-form style="padding-right: 300px" layout="inline">
        <a-form-item>
          <a-select v-model="searchType" style="width:160px" @change="handleChangeSearchType">
            <a-select-option value="type" label="任务类型">
              <span>任务类型</span>
            </a-select-option>
            <a-select-option value="status" label="状态">
              <span>任务状态</span>
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item v-if="searchType==='type'">
          <a-select v-model="searchKey.label" style="width: 250px">
            <a-select-option value="">全部</a-select-option>
            <a-select-option v-for="(label) of consoleJobLabels" :value="label" :key="label">
              {{ CONSOLE_TASK_STATE[label] }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item v-if="searchType==='status'">
          <a-select v-model="searchKey.consoleTaskState" style="width: 250px">
            <a-select-option value="">全部</a-select-option>
            <a-select-option v-for="(state) of consoleTaskStates" :value="state" :key="state">
              {{ state }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item>
          <a-button type="primary" @click="getConsoleJobList">查询</a-button>
        </a-form-item>
      </a-form>
      <div class="page-header-function">
        <a-button type="default" style="margin-right: 6px" @click="getConsoleJobList"
                  :loading="refreshLoading">
          刷新
        </a-button>
      </div>
    </div>
    <a-table stripe size="small" border :columns="asyncColumn" :data-source="showData" :pagination="false">
      <span slot="label" slot-scope="row">
        {{ CONSOLE_JOB_NAME[row] }}
      </span>
      <span slot-scope="row" slot="resourceId">
                <a @click="getJob(row)" v-if="row.dataJobName">{{ row.dataJobName }}</a>
                <span v-if="row.workerName">{{ row.workerName }}</span>
                <span v-if="row.dsInstanceId">{{ row.dsInstanceId }}</span>
      </span>
      <span slot-scope="row" slot="resourceDesc">
                <span v-if="row.dataJobDesc">{{ row.dataJobDesc }}</span>
                <span v-if="row.workerDesc">{{ row.workerDesc }}</span>
                <span v-if="row.datasourceDesc">{{ row.datasourceDesc }}</span>
      </span>
      <span slot-scope="row" slot="resourceType">
        {{ RESOURCE_TYPE[row] }}
      </span>
      <span slot="launchTime" slot-scope="row">
        {{ row.launchTime | formatTime('YYYY-MM-DD HH:mm:ss') }}
      </span>
      <span slot-scope="row" slot="action">
        <a @click="handleGoDetail(row)" style="margin-right: 16px">详情</a>
        <a v-if="row.taskState!='SUCCESS'&&row.taskState!='CANCELED'" @click="handleCancelJob(row)">取消</a>
      </span>
      <div slot="taskState" slot-scope="taskState">
        <div :style="`color:${taskState==='FAILED'?'#FF1815':taskState==='SUCCESS'?
                        '#52c41a':taskState==='CANCELED'?'#555555':''}`">
                            <span class="status-point"
                                  :style="`background-color:${taskState==='FAILED'?'#FF1815':taskState==='SUCCESS'?
                                  '#52C41A':taskState==='CANCELED'?'#555555':''}`">
                            </span>{{ CONSOLE_TASK_STATE[taskState] }}
        </div>
      </div>
    </a-table>
    <div class="page-footer-container">
      <div class="page-footer-paging">
        <Button :disabled="page===1" style="font-size: 16px;padding: 0 16px 0 10px"
                @click="handlePre">
          <Icon type="ios-arrow-back" style="font-size: 16px"/>
          上一页
        </Button>
        <span style="margin: 0 10px">第{{ page }}页</span>
        <Button :disabled="noMoreData" style="font-size: 16px;padding: 0 16px 0 10px;margin-left: 5px"
                @click="handleNext">
          <Icon type="ios-arrow-forward" style="font-size: 16px"/>
          下一页
        </Button>
      </div>
    </div>
  </div>
</template>
<script>
import { CONSOLE_JOB_NAME, CONSOLE_TASK_STATE, RESOURCE_TYPE } from '@/consts';
import { Modal } from 'ant-design-vue';

export default {
  mounted() {
    this.getConsoleJobList();
  },
  data() {
    return {
      searchType: 'type',
      firstId: 0,
      lastId: 0,
      prevFirst: [],
      page: 1,
      noMoreData: false,
      refreshLoading: false,
      CONSOLE_JOB_NAME,
      CONSOLE_TASK_STATE,
      RESOURCE_TYPE,
      searchKey: {
        consoleTaskState: '',
        label: '',
        pageData: {
          startId: 0,
          pageSize: 20
        }
      },
      size: 20,
      consoleTaskStates: [],
      consoleJobLabels: [],
      showData: [],
      asyncColumn: [
        {
          title: '名称',
          key: 'label',
          dataIndex: 'label',
          slots: { title: 'label' },
          scopedSlots: { customRender: 'label' }
        },
        {
          title: '发起者',
          dataIndex: 'launcher',
          key: 'launcher',
          width: 100
        },
        {
          title: '发起时间',
          key: 'launchTime',
          slots: { title: 'launchTime' },
          scopedSlots: { customRender: 'launchTime' },
          width: 280
        },
        {
          title: '状态',
          key: 'taskState',
          dataIndex: 'taskState',
          width: 120,
          slots: { title: 'taskState' },
          scopedSlots: { customRender: 'taskState' }
        },
        {
          title: '类型',
          key: 'resourceType',
          dataIndex: 'resourceType',
          width: 120,
          slots: { title: 'resourceType' },
          scopedSlots: { customRender: 'resourceType' }
        },
        {
          title: '资源ID',
          key: 'resourceId',
          width: 240,
          slots: { title: 'resourceId' },
          scopedSlots: { customRender: 'resourceId' }
        },
        {
          title: '资源描述',
          key: 'resourceDesc',
          slots: { title: 'resourceDesc' },
          scopedSlots: { customRender: 'resourceDesc' }
        },
        {
          title: '操作',
          key: 'action',
          slots: { title: 'action' },
          scopedSlots: { customRender: 'action' },
          width: 160
        }

      ],
      asyncData: []
    };
  },
  methods: {
    handleChangeSearchType() {
      // 切换查询类型的时候，重置所有搜索的值
      this.searchKey = {
        consoleTaskState: '',
        label: '',
        pageData: {
          startId: 0,
          pageSize: 10
        }
      };
    },
    handleRefresh() {
      this.getConsoleJobList();
    },
    async cancelConsoleJob(row) {
      const data = {
        consoleJobId: row.id,
        consoleTaskId: ''
      };
      const res = await this.$services.cancelConsoleJob({ data });
      if (res.success) {
        this.getConsoleJobList();
      }
    },
    handleGoDetail(row) {
      this.$router.push({ path: `/system/console_job/${row.id}` });
    },
    async getConsoleJobList(type) {
      const data = {
        consoleTaskState: this.searchKey.consoleTaskState,
        label: this.searchKey.label,
        startId: this.searchKey.pageData.startId,
        pageSize: this.searchKey.pageData.pageSize
      };
      const res = await this.$services.listConsoleJob({ data });
      if (res.success) {
        this.asyncData = res.data;
        this.showData = this.asyncData;
        if (type === 'next') {
          if (!this.prevFirst[this.page - 1]) {
            this.prevFirst.push(this.firstId);
          }
        }
        if (this.showData && this.showData.length > 0) {
          this.firstId = this.showData[0].id;
          this.lastId = this.showData[this.showData.length - 1].id;
        }
        if (res.data.length < this.searchKey.pageData.pageSize) {
          this.noMoreData = true;
        } else {
          this.noMoreData = false;
        }
      }
      this.refreshLoading = false;
    },
    // listConsoleTaskStates() {
    //   listConsoleTaskStates().then((res) => {
    //     if (res.data.code === '1') {
    //       this.consoleTaskStates = res.data.data;
    //     }
    //   });
    // },
    // listConsoleJobLabels() {
    //   listConsoleJobLabels().then((res) => {
    //     if (res.data.code === '1') {
    //       this.consoleJobLabels = res.data.data;
    //     }
    //   });
    // },
    handlePre() {
      this.page--;
      let startId = this.prevFirst[this.page - 1] + 1;

      if (startId < 0) {
        startId = 0;
      }
      this.searchKey.pageData.startId = startId;
      this.getConsoleJobList('prev');
    },
    handleNext() {
      this.searchKey.pageData.startId = this.lastId;
      this.getConsoleJobList('next');
      this.page++;
    },
    handlePageChange(page) {
      this.page = page;
      this.showData = this.asyncData.slice((this.page - 1) * this.size, this.page * this.size);
    },
    handlePageSizeChange(size) {
      this.size = size;
      this.showData = this.asyncData.slice((this.page - 1) * this.size, this.page * this.size);
    },
    handleCancelJob(row) {
      Modal.confirm({
        title: '取消异步任务确认',
        content: '取消之后整个任务将失败并结束。确认要取消该异步任务吗？',
        okText: '确定',
        cancelText: '取消',
        onOk: async () => {
          const data = {
            consoleJobId: row.id,
            consoleTaskId: ''
          };
          const res = await this.$services.cancelConsoleJob({ data });
          if (res.success) {
            this.$Message.success('取消异步任务成功!');
            this.getConsoleJobList();
          }
        }
      });
    }
  }
};
</script>
<style lang="less" scoped>
.console-job-wrapper {
  position: relative;

  .page-header-container {
    position: relative;

    .page-header-function {
      position: absolute;
      right: 0;
      top: 0;
    }
  }

  .page-footer-container {
    position: fixed;
    bottom: 12px;
    text-align: center;
    width: 100%;
  }
}
</style>
